.wrapper {
  background-color: white;
  border: 1px solid rgba(0, 119, 180, 0.8);
  border-radius: 3px;
  height: 88%;
}

.toolbar {
  background-color: rgba(0, 119, 180, 0.1);
  border: none;
  padding: 0 0 3px 0;
  height: 35px;
  display: flex;
  align-items: center;
}

.editor {
  background-color: white;
  padding: 10px 15px;
  height: 75%;
  overflow-y: scroll;
}

.inline-styles {
  display: flex;
  margin: 0 20px;
  height: 100%;
}
.text-align-styles {
  display: flex;
  margin: 0 20px;
  height: 100%;
}
.list-styles {
  display: flex;
  margin: 0 20px;
  height: 100%;
}
.link-styles {
  display: flex;
  margin: 0;
  height: 100%;
}
.rdw-image-wrapper {
  display: flex;
  margin: 0 0 0 20px;
  height: 100%;
}

.bold-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.bold-icon:hover {
  box-shadow: none;
}
.italic-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.italic-icon:hover {
  box-shadow: none;
}
.text-align-left-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.text-align-left-icon:hover {
  box-shadow: none;
}
.text-align-right-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.text-align-right-icon:hover {
  box-shadow: none;
}
.text-align-center-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.text-align-center-icon:hover {
  box-shadow: none;
}
.text-align-justify-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.text-align-justify-icon:hover {
  box-shadow: none;
}
.unordered-list-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.unordered-list-icon:hover {
  box-shadow: none;
}
.ordered-list-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.ordered-list-icon:hover {
  box-shadow: none;
}
.image-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.image-icon:hover {
  box-shadow: none;
}
.link-icon {
  background-color: transparent;
  margin: 0 3px 0 3px;
  padding: 0;
}
.link-icon:hover {
  box-shadow: none;
}

.rdw-option-active {
  box-shadow: none;
  background-color: white;
}
.rdw-option-wrapper {
  border: none;
}
.public-DraftStyleDefault-block {
  margin: 1px 0;
}
